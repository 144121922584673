// Generated by Framer (b12dec4)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, RichText, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["FNJBNHt6Q", "wT5kMWbl8"];

const variantClassNames = {FNJBNHt6Q: "framer-v-ardd2a", wT5kMWbl8: "framer-v-15r0ics"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transitions = {default: {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}};

const transformTemplate = (_, t) => `translateY(-50%) ${t}`

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const humanReadableVariantMap = {"Variant 1": "FNJBNHt6Q", "Variant 2": "wT5kMWbl8"}

const getProps = ({height, id, title, width, ...props}) => { return {...props, luez5bBrF: title ?? props.luez5bBrF ?? "Nav Item", variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "FNJBNHt6Q"} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap;title?: string; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale } = useLocaleInfo()

const {style, className, layoutId, variant, luez5bBrF, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "FNJBNHt6Q", transitions, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const defaultLayoutId = React.useId()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-MILmg", classNames)} style={{display: "contents"}}>
<Transition value={transition}><motion.div {...restProps} className={cx("framer-ardd2a", className)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"FNJBNHt6Q"} ref={ref} style={{backgroundColor: "rgba(138, 138, 138, 0)", ...style}} variants={{wT5kMWbl8: {backgroundColor: "rgb(138, 138, 138)"}}} {...addPropertyOverrides({wT5kMWbl8: {"data-framer-name": "Variant 2"}}, baseVariant, gestureVariant)}><RichText __fromCanvasComponent children={<React.Fragment><motion.p>Prk</motion.p></React.Fragment>} className={"framer-1l8ygtt"} layoutDependency={layoutDependency} layoutId={"KgRXBcjbZ"} style={{"--framer-link-text-color": "rgb(0, 153, 255)", "--framer-link-text-decoration": "underline", "--framer-paragraph-spacing": "0px"}} text={luez5bBrF} transformTemplate={transformTemplate} verticalAlignment={"top"} withExternalLayout/></motion.div></Transition>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-MILmg [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-MILmg .framer-1bo0l97 { display: block; }", ".framer-MILmg .framer-ardd2a { height: 19px; overflow: hidden; position: relative; width: 68px; }", ".framer-MILmg .framer-1l8ygtt { flex: none; height: auto; left: 0px; position: absolute; top: 47%; white-space: pre; width: auto; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 19
 * @framerIntrinsicWidth 68
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"wT5kMWbl8":{"layout":["fixed","fixed"]}}}
 * @framerVariables {"luez5bBrF":"title"}
 * @framerImmutableVariables false
 */
const FramerCfBTWnlgw: React.ComponentType<Props> = withCSS(Component, css, "framer-MILmg") as typeof Component;
export default FramerCfBTWnlgw;

FramerCfBTWnlgw.displayName = "Nav Item";

FramerCfBTWnlgw.defaultProps = {height: 19, width: 68};

addPropertyControls(FramerCfBTWnlgw, {variant: {options: ["FNJBNHt6Q", "wT5kMWbl8"], optionTitles: ["Variant 1", "Variant 2"], title: "Variant", type: ControlType.Enum}, luez5bBrF: {defaultValue: "Nav Item", displayTextArea: false, title: "Title", type: ControlType.String}} as any)

addFonts(FramerCfBTWnlgw, [])